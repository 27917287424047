import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Link } from "gatsby"

import { lingua_it } from "../i18n/gdpr-it-IT"
import { lingua_en } from "../i18n/gdpr-en-GB"
import { lingua_es } from "../i18n/gdpr-es-ES"
import { lingua_ca } from "../i18n/gdpr-es-CA"

const CookiePolicy = props => {
  const { lang } = props

  const [checked, setChecked] = useState(false)

  useEffect(() => {
    setChecked(JSON.parse(localStorage.getItem("3dshirt_analytics")) || false)
  }, [checked])

  const cecca = () => {
    setChecked(!checked)
    localStorage.setItem("3dshirt_analytics", !checked)
  }

  /*
  const setAnalytics = () => {
    localStorage.setItem("3dshirt_analytics", checked)
  }
  */

  let lingua = {}

  switch (lang) {
    case "it-IT":
      lingua = lingua_it.cookie
      break
    case "es-CA":
      lingua = lingua_ca.cookie
      break
    case "es-ES":
      lingua = lingua_es.cookie
      break
    case "en-GB":
      lingua = lingua_en.cookie
      break
    default:
      lingua = lingua_it.cookie
  }

  return (
    <div className="section">
      <div className="container">
        <div className="content">
          <h1 className="title is-spaced is-3">{lingua.titolo}</h1>

          <h2 className="subtitle is-4">{lingua.sottotitolo_01}</h2>
          <p>{lingua.testo_01}</p>
          <p>{lingua.testo_02}</p>

          <table className="table">
            <tbody>
              <tr>
                <td>
                  <input
                    id="idiniziali"
                    type="checkbox"
                    name="idiniziali"
                    className="checkbox"
                    checked={true}
                    onChange={() => setChecked(!checked)}
                    disabled
                  />
                </td>
                <td>
                  <h3 className="subtitle is-5">{lingua.sottotitolo_02}</h3>
                  <p className="uk-text-small">{lingua.testo_03}</p>
                  <p className="uk-text-small">{lingua.testo_04}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    id="idiniziali"
                    type="checkbox"
                    name="idiniziali"
                    className="checkbox"
                    checked={true}
                    onChange={() => setChecked(!checked)}
                    disabled
                  />
                </td>
                <td>
                  <h3 className="subtitle is-5">{lingua.sottotitolo_03}</h3>
                  <p className="uk-text-small">{lingua.testo_05}</p>
                  <p className="uk-text-small">{lingua.testo_06}</p>
                </td>
              </tr>
              <tr>
                <td className="uk-flex uk-flex-top">
                  <input
                    id="idiniziali"
                    type="checkbox"
                    name="idiniziali"
                    className="checkbox"
                    checked={checked}
                    onChange={cecca}
                  />
                </td>
                <td>
                  <h3 className="subtitle is-5">{lingua.sottotitolo_04}</h3>
                  <p>{lingua.testo_07}</p>
                  <p>{lingua.testo_08}</p>
                  <p>{lingua.testo_09}</p>

                  <h4 className="subtitle is-6">{lingua.sottotitolo_05}</h4>
                  <div className="uk-accordion-content">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td classn="uk-table-shrink">
                            <p className="uk-text-small uk-text-bold">
                              _gat _ga _gid (Google Analytics)
                            </p>
                          </td>
                          <td>
                            <p className="uk-text-small">
                              _gat, _ga, _gid {lingua.testo_10}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <h2 className="subtitle is-4">{lingua.sottotitolo_06}</h2>
          <p>
            {lingua.testo_11} {lingua.testo_12} {lingua.testo_13}
          </p>
          <p>{lingua.testo_14}</p>
          <ul>
            <li>
              Google Chrome{" "}
              <a href={lingua.chrome} target="_blank" rel="noopener noreferrer">
                {lingua.chrome}
              </a>
            </li>
            <li>
              Mozilla Firefox{" "}
              <a
                href={lingua.firefox}
                target="_blank"
                rel="noopener noreferrer"
              >
                {lingua.firefox}
              </a>
            </li>
            <li>
              Microsoft Internet Explorer{" "}
              <a
                href={lingua.explorer}
                target="_blank"
                rel="noopener noreferrer"
              >
                {lingua.explorer}
              </a>
            </li>
            <li>
              Microsoft Edge{" "}
              <a href={lingua.edge} target="_blank" rel="noopener noreferrer">
                {lingua.edge}
              </a>
            </li>
            <li>
              Opera{" "}
              <a href={lingua.opera} target="_blank" rel="noopener noreferrer">
                {lingua.opera}
              </a>
            </li>
            <li>
              Apple Safari{" "}
              <a href={lingua.safari} target="_blank" rel="noopener noreferrer">
                {lingua.safari}
              </a>
            </li>
          </ul>

          <Link to="/">{lingua.torna}</Link>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    lang: state.shop.lingua,
  }
}

export default connect(mapStateToProps)(CookiePolicy)
